import React from "react";

function Galeria() {
    return (
        <section className="galeria" id="galeria">
        <h2 className="heading">Posledné <span>detailingy</span></h2>

        <div className="galeria-container">
            <div className="galeria-box">
                <img src={require("./files/galeria/bmw1.png")} alt="detailing-bmw"/>
                <div className="galeria-layer">
                    <h4>BMW 325D M-Paket</h4>
                </div>
            </div>

            <div className="galeria-box">
                <img src={require("./files/galeria/bmw2.png")} alt="detailing-bmw"/>
                <div className="galeria-layer">
                    <h4>BMW 325D M-Paket</h4>
                </div>
            </div>

            <div className="galeria-box">
                <img src={require("./files/galeria/bmw3.png")} alt="detailing-bmw"/>
                <div className="galeria-layer">
                    <h4>BMW 325D M-Paket</h4>
                </div>
            </div>

            <div className="galeria-box">
                <img src={require("./files/galeria/fab1.png")} alt="detailing-bmw"/>
                <div className="galeria-layer">
                    <h4>Škoda Fabia II.</h4>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Galeria;