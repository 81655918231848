import React from "react";

function Baliky() {
    return (
        <div>
        <div id="particles-js"></div>
        <section className="sluzby" id="sluzby">
            <h2 className="heading">Naše <span>Služby</span></h2>
            <div className="sluzby-container">

                <div className="sluzby-box box1">
                    <i className='bx bx-cuboid' ></i>
                    <h3>Balík GOLD</h3>
                    <h2>■ Vysávanie celého interiéru</h2>
                    <h2>■ Kompletný detailing interiéru</h2>
                    <h2>■ Ručné čistenie rohoží a kobercov</h2>
                    <a href="#kontakt" className="btn">30€</a>
                </div>
                
                <div className="sluzby-box box2">
                    <i className='bx bx-star' ></i>
                    <h3>Balík PLATINUM</h3>
                    <h2>■ Vysávanie celého interiéru</h2>
                    <h2>■ Kompletný detailing interiéru</h2>
                    <h2>■ Ručné čistenie rohoží a kobercov</h2>
                    <h2>■ Oživenie čiernych plastov</h2>
                    <h2>■ Ručné čistenie okien a zrkadiel</h2>
                    <h2>■ Dezinfekcia interiéru horúcou parou</h2>
                    <a href="#kontakt" className="btn" >50€</a>
                </div>
                
                <div className="sluzby-box box3">
                    <i className='bx bx-diamond'></i>
                    <h3>Balík DIAMOND</h3>
                    <h2>■ Vysávanie celého interiéru</h2>
                    <h2>■ Kompletný detailing interiéru</h2>
                    <h2>■ Ručné čistenie rohoží a kobercov</h2>
                    <h2>■ Oživenie čiernych plastov</h2>
                    <h2>■ Ručné čistenie okien a zrkadiel</h2>
                    <h2>■ Dezinfekcia interiéru horúcou parou</h2>
                    <h2>■ Ručné umývanie kolies a diskov</h2>
                    <h2>■ Eliminácia nežiadúcich pachov interiéru</h2>
                    <a href="#kontakt" className="btn" >80€</a>
                </div>

            </div>
        </section>
    </div>
    )
}

export default Baliky;