import React from "react";
import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

function Kontakt() {
    const [capVal, setCapVal] = useState(null);

    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_pzqfh5n", "template_h6mrtry", form.current, "HZxrLcp24pz2dEKEC")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      alert("Vaša objednávka bola úspešne odoslaná");
    };

    return (
        <section className="kontakt" id="kontakt">
            <h2 className="heading">Kontaktuj <span>Nás!</span></h2>

            <form ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                    <input type="text" placeholder="Meno a priezvisko" id="name" name="user_name"/>
                    <input type="email" placeholder="Emailová adresa" id="email" name="user_email" required/>
                </div>
                <div className="input-box">
                    <input type="text" placeholder="PSČ, adresa a číslo domu" id="adresa" name="user_adress"/>
                    <input type="tel" id="phone" name="user_phone" placeholder="0905 123 456" required/>
                </div>
                <div className="input-box">
                    <select id="balik" name="Balik_GOLD" form="balikList" required>
                        <option value="Balík GOLD" name="Balik_GOLD">Balík GOLD</option>
                        <option value="Balík PLATINUM" name="Balik_PLATINUM">Balík PLATINUM</option>
                        <option value="Balík DIAMOND" name="Balik_DIAMOND">Balík DIAMOND</option>
                    </select>
                    <select id="dopln" name="user_dopln" form="doplnList">
                        <option value="nič">Žiadna doplnková služba</option>
                        <option value="Leštenie svetlometov">Leštenie svetlometov</option>
                        <option value="Detailing kolies a diskov">Detailing kolies a diskov</option>
                        <option value="Definfekcia a čistenie parou">Dezinfekcia a čistenie parou</option>
                        <option value="Oživenie čiernych plastov">Oživenie čiernych plastov</option>
                    </select>
                </div>
                <textarea name="user_message" id="message" cols="30" rows="10" placeholder="Vaša správa, špeciálne požiadavky, etc."></textarea>
                <ReCAPTCHA sitekey="6Ldi1hkmAAAAAOek1F1mYkCN3wEv7EQFyyaIetDf" onChange={(val) => setCapVal(val)} required/>
                <button disabled={!capVal} type="submit" className="btn">Odoslať správu</button>
            </form>
        </section>
    )
}

export default Kontakt;