import "./style/style.css";
import Navbar from "./comp/nav";
import Domov from "./comp/domov";
import Onas from "./comp/onas";
import Baliky from "./comp/baliky";
import Galeria from "./comp/galeria";
import Kontakt from "./comp/kontakt";
import Footer from "./comp/footer";
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar/>
      <Domov/>
      <Onas/>
      <Baliky/>
      <Galeria/>
      <Kontakt/>
      <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;