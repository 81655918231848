import React from "react";

function Onas() {
    return (
        <section className="onas" id="onas">
            <div className="onas-content">
                <h2 className="heading">Niečo o <span>Nás</span></h2>
                <h3>Mobilné detailingové štúdio</h3>
                <p>
                Sme mladý no <span>spoľahlivý</span> tím ktorý sa zaoberá mobilným detailingom
                osobných automobilov v domácnostiach, no problém nám nerobia ani osobné
                a dodávkové či nákladné automobily ktoré slúžia firmám a využívajú sa 
                denno-denne naplno.<br/><br/>Spomeňte si kedy naposledy dostalo Vaše auto detailnu 
                ručnú očistu. Chápeme ak nemáte po náročnom dni v práci čas a ani chuť tráviť 
                nekonečné minúty v zápchach cestou na najbližšiu autoumývarku.<br/><br/>
                Práve preto <span>odvedieme všetkú prácu na Vašom aute priamo u Vás doma na dvore či parkovisku.</span><br/> 
                O vozidlá sa počas čistenia a detailingu staráme ako o svoje vlastné a najdôležitejšia 
                je pre nás práve Vaša spokojnosť. <br/><br/>Navyše, všetky služby poskytujeme <span>bez nutnosti odberu 
                vody a energie od zákazníka.</span><br/>Presvedčte sa na vlastné oči a pokračujte na 
                výber balíka alebo služby na stránku <span>SLUŽBY</span>.
                </p>
                <a href="#sluzby" className="btn">Služby</a>
            </div>
    </section>
    )
}

export default Onas;