import React, { useState } from "react";
import { Link } from "react-scroll";

function Navbar() {
    const [navOpen, setNavOpen] = useState(true);

    return (
        <header className="header">
            <img src={require("./files/logo-domov.png")} alt="logo-crystall" className="logo-nav"/>

           <h1>0918 722 720</h1>

            <i className={navOpen ? "bx bx-menu" : " bx bx-x"} id="menu-icon" onClick={() => setNavOpen(!navOpen)}></i>

            <nav className={navOpen ? "navbar" : "navbar active"}>
                <Link to="domov" spy={true} smooth={true} offset={10} duration={100}>Domov</Link>
                <Link to="onas" spy={true} smooth={true} offset={10} duration={100}>O nás</Link>
                <Link to="sluzby" spy={true} smooth={true} offset={10} duration={100}>Balíky</Link>
                <Link to="galeria" spy={true} smooth={true} offset={10} duration={100}>Galéria</Link>
                <Link to="kontakt" spy={true} smooth={true} offset={10} duration={100}>Kontakt</Link>
            </nav>
        </header>
    )
}

export default Navbar;